import * as React from "react";

const SmPnl = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="#191B20" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.297 6.987c-.05.036-.682.801-1.404 1.702-2.086 2.605-2.212 2.77-2.157 2.825.033.033.33.056.916.07.798.018.872.026.926.1.048.066.058.39.058 1.87 0 1.742.003 1.792.086 1.909l.085.12h3.2l.065-.1c.056-.084.066-.35.077-1.915.011-1.68.018-1.822.086-1.89.064-.065.184-.077.939-.099.475-.013.877-.038.893-.053.016-.016.004-.07-.027-.12-.23-.373-3.507-4.455-3.59-4.471-.034-.007-.103.017-.153.052Z"
      fill="#4FBF67"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.213 17.448c.05-.036.677-.806 1.392-1.71 2.07-2.62 2.194-2.785 2.139-2.84-.034-.032-.33-.053-.917-.063-.797-.014-.872-.022-.926-.095-.049-.066-.06-.39-.07-1.869-.012-1.743-.014-1.792-.098-1.908l-.086-.12-1.6.01-1.6.011-.064.099c-.056.085-.064.35-.065 1.916 0 1.68-.006 1.823-.074 1.891-.063.065-.183.078-.938.105-.475.017-.877.043-.893.059-.016.016-.003.07.028.12.232.37 3.535 4.432 3.619 4.448.034.006.103-.018.153-.054Z"
      fill="#FA2256"
    />
  </svg>
);

export default SmPnl;
