import styled from '@emotion/styled';

export const Container = styled.div`
  background-color: #141823;
  max-width: 450px;
  padding: 28px 42px;
  line-height: 25px;
  border-radius: 12px;
  height: auto;
  align-self: stretch;
`;
