import * as React from 'react';
import { TradeIconProp } from './UpDown';

function AboveBelow(props: TradeIconProp) {
  return (
    <svg width={13} height={20} fill="none" {...props}>
      <path
        d="M6.742 20c-.717-.907-1.44-1.814-2.156-2.727C3.1 15.37 1.613 13.475.133 11.569c-.087-.11-.169-.305-.116-.403.052-.092.268-.157.413-.157 1.994-.01 3.981-.01 5.974-.005 2.052 0 4.097 0 6.149.01.146 0 .361.044.425.136.059.087-.011.294-.087.402-1.86 2.69-3.724 5.379-5.595 8.062-.093.136-.216.256-.32.38C6.9 20 6.817 20 6.741 20z"
        fill={props.active ? '#D34A4A' : '#82828F'}
      />
      <path
        d="M6.206 0c.256.304.53.597.774.907 1.699 2.167 3.392 4.334 5.085 6.506.28.359.564.712.832 1.081.07.092.134.26.087.331-.058.093-.233.158-.355.163-.855.017-1.704.011-2.56.011H2.046c-.553 0-1.105.006-1.658-.01-.128-.006-.314-.06-.367-.147-.052-.087 0-.266.07-.364C1.645 6.224 3.21 3.97 4.775 1.722c.337-.484.669-.973 1.012-1.456.07-.098.163-.174.244-.26C6.09 0 6.148 0 6.206 0z"
        fill={props.active ? '#3DECA7' : '#6B6C79'}
      />
    </svg>
  );
}

const MemoAboveBelow = React.memo(AboveBelow);
export default MemoAboveBelow;
