import * as React from 'react';
export interface TradeIconProp extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}
function UpDown(props: TradeIconProp) {
  return (
    <svg width={18} height={21} fill="none" {...props}>
      <path
        d="M10.632 15.033v-1.721c0-1.283 0-2.563-.003-3.846 0-.566.328-.955.9-.981a27.686 27.686 0 011.902 0 .99.99 0 01.641.275c.174.16.256.352.256.592-.003 1.83-.003 3.658-.003 5.487v.2h.174c.705 0 1.414 0 2.119-.003.247 0 .482.05.685.198.29.209.32.473.081.737-1.352 1.51-2.705 3.019-4.06 4.526-.413.458-1.266.461-1.679 0-1.355-1.507-2.708-3.017-4.06-4.526-.247-.273-.213-.552.095-.758.203-.136.436-.18.676-.18.706 0 1.414 0 2.12-.002.043.002.09.002.156.002z"
        fill={props.active ? '#D34A4A' : '#6B6C79'}
      />
      <path
        d="M3.218 5.803c-.775 0-1.524.006-2.273-.003a1.943 1.943 0 01-.482-.078.805.805 0 01-.273-.157c-.235-.192-.253-.444-.05-.67.485-.546.976-1.09 1.464-1.632.87-.973 1.744-1.945 2.615-2.915.418-.464 1.272-.464 1.69 0 1.338 1.49 2.673 2.982 4.011 4.47l.096.111c.16.197.154.404-.02.586-.21.218-.476.288-.766.288-.712.003-1.423.003-2.134.003h-.183v5.57c0 .566-.33.955-.9.979-.633.026-1.265.014-1.901 0a.964.964 0 01-.682-.314.757.757 0 01-.212-.551c.003-1.83.003-3.658.003-5.484-.003-.067-.003-.128-.003-.203z"
        fill={props.active ? '#3DECA7' : '#6B6C79'}
      />
    </svg>
  );
}

const MemoUpDown = React.memo(UpDown);
export default MemoUpDown;
