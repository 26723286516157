import { toFixed } from '@Utils/NumString';
import { add, divide, gt } from '@Utils/NumString/stringArithmatics';
import { useOneCTWallet } from '@Views/OneCT/useOneCTWallet';
import { ColumnGap } from '@Views/ABTradePage/Components/Column';
import { LightToolTipSVG } from '@Views/ABTradePage/Components/LightToolTipSVG';
import {
  RowBetween,
  RowGap,
  RowGapItemsStretched,
  RowGapItemsTop,
} from '@Views/ABTradePage/Components/Row';
import { BuyTradeHeadText } from '@Views/ABTradePage/Components/TextWrapper';
import { buyTradeDataAtom } from '@Views/ABTradePage/Hooks/useBuyTradeData';
import { useSwitchPool } from '@Views/ABTradePage/Hooks/useSwitchPool';
import { tradeSizeAtom } from '@Views/ABTradePage/atoms';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import { BuyUSDCLink } from '../BuyUsdcLink';
import { PoolDropdown } from './PoolDropdown';
import { TradeSizeInput } from './TradeSizeInput';
import { WalletBalance, formatBalance } from './WalletBalance';
import { isAvailable } from '@Utils/NumberUtils';

const TradeSizeSelectorBackground = styled.div`
  margin-top: 15px;
  width: 100%;
`;

export const TradeSizeSelector: React.FC<{
  onSubmit?: any;
}> = ({ onSubmit }) => {
  const { switchPool, poolDetails } = useSwitchPool();
  const readcallData = useAtomValue(buyTradeDataAtom);
  console.log(`index-readcallData: `, readcallData);
  const { registeredOneCT } = useOneCTWallet();

  if (!poolDetails || !readcallData || !switchPool) return <></>;

  const decimals = poolDetails.decimals;
  const balance = divide(readcallData.balance ?? 0, decimals) as string;
  const tradeToken = poolDetails.token;
  const minFee = divide(switchPool.min_fee || '0', decimals) as string;
  const maxFee = divide(
    readcallData.maxTradeSizes[switchPool.optionContract] ?? '0',
    decimals
  ) as string;
  const platformFee = divide(switchPool.platformFee, decimals) as string;
  const maxTradeSize = maxFee;
  return (
    <TradeSizeSelectorBackground>
      <ColumnGap gap="7px" className="w-full">
        <RowBetween>
          <RowGap gap="4px">
            <BuyTradeHeadText>Trade Size</BuyTradeHeadText>
          </RowGap>

          <WalletBalance
            balance={formatBalance(toFixed(balance, 2))}
            unit={tradeToken}
          />
        </RowBetween>
        <RowGapItemsStretched gap="0px" className="w-full">
          <TradeSizeInput
            maxTradeSize={maxTradeSize}
            registeredOneCT={!!registeredOneCT}
            tokenName={tradeToken}
            balance={balance}
            platformFee={platformFee}
            minTradeSize={minFee}
            onSubmit={onSubmit}
          />

          <PoolDropdown />
        </RowGapItemsStretched>
        {registeredOneCT && (
          <PlatfromFeeError
            platfromFee={platformFee}
            tradeToken={tradeToken}
            balance={balance}
          />
        )}
      </ColumnGap>
    </TradeSizeSelectorBackground>
  );
};

const PlatfromFeeError = ({
  platfromFee,
  tradeToken,
  balance,
}: {
  platfromFee: string;
  tradeToken: string;
  balance: string;
}) => {
  const tradeSize = useAtomValue(tradeSizeAtom);
  const notEnoughForTrade = gt(tradeSize || '0', balance);
  const notEnooghForFee = gt(add(tradeSize || '0', platfromFee), balance);
  const isError = notEnooghForFee;
  if (notEnooghForFee && notEnoughForTrade) return <></>;

  if (isAvailable(balance) && isAvailable(platfromFee))
    return (
      <RowGapItemsTop
        gap="4px"
        className={`text-${isError ? 'red' : '[#7F87A7]'} text-f10`}
      >
        <LightToolTipSVG className="mt-[3px]" />
        {isError ? (
          <>
            Insufficient funds for platform fee.{' '}
            <BuyUSDCLink token={tradeToken as 'ARB'} />
          </>
        ) : (
          !isError && (
            <>
              Platform fee : + {platfromFee} {tradeToken}
            </>
          )
        )}
      </RowGapItemsTop>
    );
  return <></>;
};
