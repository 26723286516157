const ShareIcon = (props) => (
  <svg
    width={27}
    height={27}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse
      cx={13.48}
      cy={13.314}
      rx={12.757}
      ry={12.726}
      fill={props.bgColor ? props.bgColor : '#171722'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.277 6.765c-.98.207-1.684.92-1.872 1.895-.053.276-.052.554.003.813.024.112.04.206.036.21-.004.003-.987.495-2.185 1.092l-2.178 1.087-.133-.146a2.596 2.596 0 0 0-1.148-.688c-.257-.069-.856-.07-1.103-.003-.628.172-1.097.506-1.43 1.015-.261.401-.365.763-.365 1.276 0 .512.104.876.364 1.272.337.514.803.846 1.43 1.018.248.068.847.066 1.104-.003.414-.111.878-.389 1.148-.687l.133-.146 2.178 1.086c1.198.598 2.181 1.09 2.185 1.093.004.003-.014.105-.04.225-.053.254-.04.681.03.94a2.377 2.377 0 0 0 1.013 1.416c.396.26.76.363 1.272.363.665 0 1.201-.219 1.66-.678.46-.459.678-.995.678-1.66 0-.512-.104-.877-.363-1.272-.34-.518-.8-.846-1.43-1.019-.248-.067-.848-.066-1.105.003-.413.112-.877.39-1.148.688l-.132.146-2.178-1.087a615.336 615.336 0 0 1-2.186-1.093 1.481 1.481 0 0 1 .037-.21c.055-.26.055-.531 0-.79a1.479 1.479 0 0 1-.037-.21c.004-.004.988-.496 2.186-1.093l2.178-1.087.132.146c.271.299.735.576 1.148.688.258.069.857.07 1.104.003.627-.173 1.094-.505 1.431-1.019.26-.396.364-.76.364-1.272s-.105-.876-.364-1.272c-.336-.513-.802-.846-1.416-1.014-.229-.062-.765-.076-1-.026Z"
      fill="#C3C2D4"
    />
  </svg>
);

export default ShareIcon;
