import { SVGProps } from 'react';

export const FourCharts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2.031 1.625a.407.407 0 0 0-.406.406V4.47c0 .223.183.406.406.406H4.47a.407.407 0 0 0 .406-.406V2.03a.407.407 0 0 0-.406-.406H2.03Zm-1.219.406A1.22 1.22 0 0 1 2.032.812h2.437a1.22 1.22 0 0 1 1.218 1.22v2.437A1.22 1.22 0 0 1 4.47 5.687H2.03A1.22 1.22 0 0 1 .812 4.47V2.03Zm1.22 6.094a.407.407 0 0 0-.407.406v2.438c0 .223.183.406.406.406H4.47a.407.407 0 0 0 .406-.406V8.53a.407.407 0 0 0-.406-.406H2.03Zm-1.22.406a1.22 1.22 0 0 1 1.22-1.219h2.437a1.22 1.22 0 0 1 1.218 1.22v2.437a1.22 1.22 0 0 1-1.218 1.219H2.03a1.22 1.22 0 0 1-1.219-1.22V8.532ZM10.97 1.625H8.53a.407.407 0 0 0-.406.406V4.47c0 .223.183.406.406.406h2.438a.407.407 0 0 0 .406-.406V2.03a.407.407 0 0 0-.406-.406ZM8.53.812h2.438a1.22 1.22 0 0 1 1.219 1.22v2.437a1.22 1.22 0 0 1-1.22 1.218H8.532A1.22 1.22 0 0 1 7.312 4.47V2.03A1.22 1.22 0 0 1 8.533.812Zm0 7.313a.407.407 0 0 0-.406.406v2.438c0 .223.183.406.406.406h2.438a.407.407 0 0 0 .406-.406V8.53a.407.407 0 0 0-.406-.406H8.53Zm-1.219.406a1.22 1.22 0 0 1 1.22-1.219h2.437a1.22 1.22 0 0 1 1.219 1.22v2.437c0 .673-.546 1.219-1.22 1.219H8.532a1.22 1.22 0 0 1-1.219-1.22V8.532Z"
    />
  </svg>
);
