import { SVGProps } from 'react';

export const TwoChartHorizontal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.188 4.063c0 .449-.364.812-.813.812h-9.75a.812.812 0 0 1-.813-.813V2.438c0-.449.364-.812.813-.812h9.75c.45 0 .813.363.813.813v1.624Zm-.813 1.625c.896 0 1.625-.73 1.625-1.625V2.438c0-.897-.729-1.626-1.625-1.626h-9.75C.729.813 0 1.542 0 2.438v1.626c0 .896.729 1.625 1.625 1.625h9.75ZM13 10.563V8.937c0-.896-.729-1.624-1.625-1.624h-9.75C.729 7.313 0 8.04 0 8.938v1.624c0 .897.729 1.626 1.625 1.626h9.75c.896 0 1.625-.73 1.625-1.626Zm-1.625.812h-9.75a.812.812 0 0 1-.813-.813V8.938c0-.45.364-.813.813-.813h9.75c.45 0 .813.363.813.813v1.624c0 .45-.364.813-.813.813Z"
    />
  </svg>
);
