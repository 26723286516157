export const EditIconSVG: React.FC<{
  className?: string;
  svgProps?: React.SVGProps<SVGSVGElement>;
}> = ({ className, svgProps }) => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...svgProps}
    >
      <g clipPath="url(#clip0_450_383)">
        <path
          d="M11.8118 2.99316H11.0713C6.16385 2.99316 2.18555 6.97146 2.18555 11.8789V11.879C2.18555 16.7865 6.16385 20.7648 11.0713 20.7648H11.8118C16.7192 20.7648 20.6975 16.7865 20.6975 11.879V11.8789C20.6975 6.97146 16.7192 2.99316 11.8118 2.99316Z"
          fill="#C2C1D3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.56483 0.829174C8.01108 1.12249 6.25832 1.91201 5.0436 2.86561C4.197 3.53018 2.99032 4.86013 2.41801 5.75913C0.63824 8.55524 0.190522 12.4192 1.27999 15.5802C2.60552 19.4261 5.80572 22.2425 9.64355 22.9408C10.5662 23.1087 12.3625 23.1085 13.2828 22.9402C17.1234 22.2383 20.411 19.2783 21.6532 15.4039C23.1065 10.8715 21.5525 5.71152 17.8797 2.87459C16.5454 1.84395 14.6114 1.00553 13.0604 0.785162C12.0684 0.644243 10.4353 0.664802 9.56483 0.829174ZM15.1985 7.5751C16.1337 8.46133 16.3523 8.79666 16.1884 9.09357C16.0618 9.32281 15.2976 10.1605 15.2201 10.1549C15.1817 10.1521 14.6116 9.61243 13.9532 8.95564L12.756 7.76143L13.2983 7.21412C13.603 6.90654 13.928 6.66682 14.0403 6.66682C14.1578 6.66682 14.6341 7.04027 15.1985 7.5751ZM13.2811 9.86113L14.4764 11.0495L13.5187 12.0767C12.9919 12.6417 11.9529 13.7633 11.2099 14.569L9.85865 16.0339L8.33334 16.54C7.4945 16.8184 6.77806 17.0461 6.74138 17.0461C6.60692 17.0461 6.68478 16.564 7.0534 15.1139C7.26073 14.2981 7.5082 13.5353 7.6033 13.4189C7.99988 12.9326 11.9769 8.66323 12.029 8.66782C12.0601 8.67061 12.6236 9.20754 13.2811 9.86113Z"
          fill="#303044"
        />
      </g>
      <defs>
        <clipPath id="clip0_450_383">
          <rect width="23" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
