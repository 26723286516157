import { SVGProps } from 'react';

export const TwoChartsVertical = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={15}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.916 13.197a.812.812 0 0 1-.81-.816l.037-9.75a.812.812 0 0 1 .815-.81l1.625.007c.45.001.811.366.81.815l-.036 9.75a.812.812 0 0 1-.816.81l-1.625-.006Zm-1.622-.819a1.627 1.627 0 0 0 1.62 1.631l1.624.006a1.626 1.626 0 0 0 1.631-1.62l.036-9.749a1.627 1.627 0 0 0-1.62-1.631l-1.624-.006A1.627 1.627 0 0 0 7.33 2.63l-.036 9.749Zm-4.88 1.607 1.624.006a1.626 1.626 0 0 0 1.631-1.619l.036-9.75A1.627 1.627 0 0 0 4.086.992L2.461.984a1.627 1.627 0 0 0-1.63 1.62l-.037 9.75a1.627 1.627 0 0 0 1.62 1.63Zm-.807-1.628.036-9.75a.812.812 0 0 1 .815-.81l1.625.007c.45.001.811.366.81.815l-.036 9.75a.812.812 0 0 1-.816.81l-1.625-.006a.812.812 0 0 1-.81-.816Z"
    />
  </svg>
);
